import { effect, EffectRef, inject, Injectable, Signal, signal, untracked, WritableSignal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';

import { interval, Observable, tap, finalize } from 'rxjs';

import { ClientPatientMonitorMessage } from '@core/models/clientPatientMonitorMessage';
import { ApiService } from '@core/services/api.service';
import { AuthService } from '@core/services/auth/auth.service';
import { DashboardTokenService } from '@core/services/dashboard-token.service';
import { TimeService } from '@core/services/time.service';

@Injectable({
  providedIn: 'root'
})
export class PatientMonitorService {
  private apiService: ApiService = inject(ApiService);
  private authService: AuthService = inject(AuthService);
  private timeService: TimeService = inject(TimeService);
  private dashboardTokenService: DashboardTokenService = inject(DashboardTokenService);

  private _authEffect: EffectRef = effect(
    () => {
      if (this.authService.authenticatedOrDashboardTokenSet()) {
        this.getPatientMonitorMessagesFromApi();
      } else {
        this.patientMonitorMessages.set([]);
        this.requestInProgress = false;
      }
    },
    { allowSignalWrites: true }
  );

  private expiredMessageCheckerTimer: Signal<void> = toSignal(interval(10_000), { rejectErrors: true });
  private _expiredMessageCheckerEffect: EffectRef = effect(
    () => {
      this.expiredMessageCheckerTimer();
      untracked(() => {
        this.patientMonitorMessages.update(messages => {
          const updatedMessages = [...messages];
          return this.clearExpiredMessages(updatedMessages);
        });
      });
    },
    { allowSignalWrites: true }
  );

  private refreshEntitiesTimer: Signal<void> = toSignal(interval(600_000), { rejectErrors: true });
  private _refreshEntitiesEffect: EffectRef = effect(() => {
    this.refreshEntitiesTimer();
    untracked(() => {
      if (this.authService.authenticatedOrDashboardTokenSet()) {
        this.getPatientMonitorMessagesFromApi();
      }
    });
  });

  private requestInProgress: boolean = false;

  public patientMonitorMessages: WritableSignal<Array<ClientPatientMonitorMessage>> = signal([]);
  public patientMonitorMessages$: Observable<Array<ClientPatientMonitorMessage>> = toObservable(
    this.patientMonitorMessages
  );

  private getPatientMonitorMessagesFromApi(): void {
    if (this.requestInProgress) {
      return;
    }
    this.requestInProgress = true;
    const dashboardToken = this.dashboardTokenService.dashboardToken();
    this.apiService
      .get(dashboardToken ? '/patientmonitor/message/token/' + dashboardToken : '/patientmonitor/message')
      .pipe(
        tap((patientMonitorMessages: Array<ClientPatientMonitorMessage>) =>
          this.patientMonitorMessages.set(patientMonitorMessages)
        ),
        finalize(() => (this.requestInProgress = false))
      )
      .subscribe();
  }

  public updatePatientMonitorMessages(newMessage: ClientPatientMonitorMessage): void {
    this.patientMonitorMessages.update(patientMonitorMessages => {
      const updatedPatientMonitorMessages = [...patientMonitorMessages];
      const index = updatedPatientMonitorMessages.findIndex(
        (patientMonitorMessage: ClientPatientMonitorMessage) => patientMonitorMessage.id === newMessage.id
      );
      if (index >= 0) {
        updatedPatientMonitorMessages[index] = newMessage;
      } else {
        updatedPatientMonitorMessages.push(newMessage);
      }
      return this.clearExpiredMessages(updatedPatientMonitorMessages);
    });
  }

  clearExpiredMessages(messages: Array<ClientPatientMonitorMessage>): Array<ClientPatientMonitorMessage> {
    const now = this.timeService.currentTime();
    return messages.filter(
      (patientMonitorMessage: ClientPatientMonitorMessage) => patientMonitorMessage.timestamp >= now - 86400000
    );
  }
}
